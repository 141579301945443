(function(window, $){

    "use strict";

    $(document).ready(function() {
	    $(".item").each(function() {
	    	var img = $(this).find("img");
	    	var title = img.attr("title");
	    	
	    	if(typeof title === "undefined" || title === "") {
	    		return;
	    	}
	    	
	    	var html = '<div class="title">'+ title +'</div>'; 
	    	img.after(html);
	    });
    });
})(window, jQuery || null);